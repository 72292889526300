export const theme = {
	palette: {
		background: {
			default: "#FFFFFF"
		},
		primary: {
			light: "#5786C4",
			main: "#5786C4",
			dark: "#5786C4"
		},
		secondary: {
			light: "#5786C4",
			main: "#5786C4",
			dark: "#5786C4"
		},
		text: {
			primary: "#020510",
			secondary: "#020510"
		}
	}
};
